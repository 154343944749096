<template>
  <div class="products-locked-card">
    <div class="card-title">
      <img v-if="creditScore" src="@/assets/images/credit-passport/credit-health-check-blue.svg" alt="credit-passport" width="40" height="40" />
      <img v-else-if="isEquity" alt="equity locked icon" src="@/assets/images/products/equity-green.svg" width="50" height="50" />
      <span v-else class="material-icons"> lock </span>
      <div class="title">{{ lockedTitle }}</div>
      <p class="subtitle" v-if="lockedSubtitle">{{ lockedSubtitle }}</p>
    </div>
    <div v-if="!isEquity" class="timeline-container">
      <div class="timeline">
        <img :src="require(`@/assets/images/matches/${icon1}.svg`)" class="icon" alt="icon 1" />
        <div :class="[{ 'done-dot': creditScore && showModal }, 'dot-active']">
          <em v-if="creditScore && showModal" class="icon material-icons">done</em>
        </div>
        <div class="copy-active">{{ creditScore ? '1. Link your bank details through TrueLayer' : '1. Choose your product' }}</div>
      </div>
      <div class="timeline">
        <img :src="require(`@/assets/images/matches/${icon2}.svg`)" class="icon-unlock" alt="icon 2" />
        <div class="dot"></div>
        <div class="copy">{{ creditScore ? '2. We’ll share your data with Credit Passport' : '2. Answer a few questions' }}</div>
      </div>
      <div class="timeline">
        <img :src="require(`@/assets/images/matches/${icon3}.svg`)" class="icon-unlock" alt="icon 3" />
        <div class="dot"></div>
        <div class="copy">{{ creditScore ? '3. Understand your credit profile' : '3. Swoop review' }}</div>
      </div>
    </div>
    <div v-else class="timeline-container">
      <div class="timeline">
        <div class="bullet"></div>
        <div class="text">1. Choose your product</div>
      </div>
      <div class="timeline">
        <div class="bullet"></div>
        <div class="text">2. Answer a few questions</div>
      </div>
      <div class="timeline disabled">
        <div class="bullet"></div>
        <div class="text text-disabled">3. Swoop review</div>
      </div>
    </div>
    <div v-if="creditScore && isBankMandateList && bankMandateList[0].status !== 'ProcessingCompleted'" class="card-title">
      <span class="material-icons hourglass"> hourglass_empty </span>
      <div class="subtitle">One Moment..</div>
      <div class="subtitle">We are integrating your bank details and processing the data for your credit report.</div>
    </div>
    <button
      v-else-if="creditScore && isBankMandateList"
      type="button"
      @click="sendToCreditScorePage()"
      class="btn btn-primary btn-sm"
      :disabled="creditScore && isBankMandateList && bankMandateList[0].status !== 'ProcessingCompleted'"
      v-promise-btn
    >
      Continue
    </button>
    <BankIntegrationBtnTrueLayer v-if="creditScore && !isBankMandateList" @click.native="sendToAmplitude()" btnLabel="Link your bank details" />
    <router-link v-else-if="!creditScore" :to="{ name: lockedRouteName, query: { fundingReason: filterGoalsByType } }" class="btn btn-primary btn-sm"
      >Provide more information</router-link
    >
  </div>
</template>

<script>
import BankIntegrationBtnTrueLayer from '@/components/integrations/BankIntegrationBtnTrueLayer'
import { mapGetters, mapActions } from 'vuex'
import { bankMandateListRoutine, bankGetters } from '@/store/modules/bank/routines'
import { amplitudeEvents } from '@/utils/constants'

export default {
  props: {
    lockedTitle: {
      type: String,
      required: true
    },
    lockedSubtitle: {
      type: String
    },
    icon1: {
      type: String,
      required: true
    },
    icon2: {
      type: String,
      default: 'question'
    },
    icon3: {
      type: String,
      default: 'matches'
    },
    lockedRouteName: {
      type: String,
      required: true
    },
    creditScore: {
      type: Boolean,
      default: true
    },
    companyId: {
      type: String,
      default: ''
    },
    filterGoalsByType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showModal: false,
      bankMandateTimerId: null
    }
  },
  components: { BankIntegrationBtnTrueLayer },
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions({
      getBankMandateList: bankMandateListRoutine.TRIGGER
    }),
    async requestBankMandate() {
      try {
        await this.getBankMandateList(this.companyId) // FetchingData, FetchingDataCompleted, ProcessingCompleted, Failed
      } catch (err) {
        console.error(err)
        return
      }
      if (!this.bankMandateStatus || this.bankMandateStatus === 'ProcessingCompleted' || this.bankMandateStatus === 'Failed') {
        return
      }
      return new Promise(resolve => {
        this.bankMandateTimerId = setTimeout(() => {
          resolve(this.requestBankMandate())
        }, 2000)
      })
    },
    init() {
      if (this.companyId) {
        return Promise.all([this.requestBankMandate()])
      }
    },
    sendToCreditScorePage() {
      this.$router.push({ name: 'credit-score' })
    },
    sendToAmplitude() {
      this.$ma.trackEvent(amplitudeEvents.CreditScore.CTA_LINK_YOUR_BANK_DETAILS)
    }
  },
  computed: {
    ...mapGetters({
      bankMandateList: bankGetters.BANK_MANDATE_LIST,
      bankMandateStatus: bankGetters.BANK_MANDATE_STATUS
    }),
    isEquity() {
      return this.filterGoalsByType === 'findInvestor'
    },
    isBankMandateList() {
      return !!this.bankMandateList && !!this.bankMandateList.length
    }
  },
  beforeMount() {
    this.init()
  },
  beforeDestroy() {
    clearTimeout(this.bankMandateTimerId)
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

.products-locked-card {
  margin-top: 50px;
  border: 1px solid var(--color-secondary-50);
  box-shadow: 0px 0px 1px rgba(0, 62, 82, 0.16), 0px 1px 3px rgba(0, 62, 82, 0.16);
  background-color: $color-white;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .card-title {
    margin: 52px 0;
    text-align: center;
    .material-icons {
      font-size: 45px;
      color: var(--color-primary-500);
    }
    .hourglass {
      font-size: 45px;
      color: var(--color-primary-500);
      animation-name: spin;
      animation-duration: 2000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;

      @keyframes spin {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
    }

    .title {
      color: var(--color-primary-500);
      font-size: 24px;
      font-weight: bold;
      margin: 15px 0;
    }

    .subtitle {
      color: var(--color-primary-400);
      font-size: 16px;
      max-width: 700px;
    }
  }

  .timeline-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    margin-bottom: 45px;
    width: 100%;
    max-width: 900px;
    @media only screen and (max-width: $breakpoint-sm-max) {
      padding: 0 20px;
    }
    .timeline {
      flex: 1;
      text-align: center;
      position: relative;
      @media only screen and (max-width: $breakpoint-sm-max) {
        width: 100px;
        margin-bottom: 20px;
      }
      .icon {
        width: 60px;
        height: 60px;
        margin-bottom: 20px;
      }

      .icon-unlock {
        width: 60px;
        height: 60px;
        margin-bottom: 20px;
      }

      .copy-active {
        border-top: 1px solid var(--color-primary-500);
        font-size: 12px;
        padding-top: 20px;
        color: var(--color-primary-500);
      }
      .copy {
        border-top: 1px solid var(--color-primary-300);
        font-size: 12px;
        padding-top: 20px;
        color: var(--color-primary-300);
      }
      .text {
        border-top: 2px solid #2e9c8e;
        font-size: 12px;
        padding-top: 20px;

        &-disabled {
          border-top: 2px solid var(--color-primary-100);
        }
        @media only screen and (max-width: $breakpoint-sm-max) {
          padding-right: 20px;
        }
      }
      .bullet {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: -8%;
        background-color: #2e9c8e;
        @media only screen and (max-width: $breakpoint-sm-max) {
          top: -5%;
        }
      }

      .dot-active {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background-color: var(--color-primary-500);
        position: absolute;
        right: 49%;
        top: 63%;
      }

      .dot {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background-color: var(--color-primary-300);
        position: absolute;
        right: 49%;
        top: 63%;
      }
      .done-dot {
        height: 25px;
        width: 25px;
        border-radius: 50%;
        background-color: var(--color-secondary-500);
        position: absolute;
        right: 47%;
        top: 60%;
        @media only screen and (max-width: $breakpoint-md-max) {
          top: 50%;
        }
        .material-icons {
          color: $color-white;
          position: absolute;
          right: calc(100% - 42px);
          font-weight: bold;
        }
      }
    }
  }

  .btn {
    margin-bottom: 50px;
    padding: 12px 50px;
  }
}
</style>
