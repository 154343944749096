<template>
  <div class="ui active inverted dimmer page-loader" v-if="loading">
    <div class="ui medium text loader"><spinner :size="64" :loader="true" color /></div>
  </div>
  <div v-else>
    <Header />
    <div class="background">
      <div class="wave-green" />
      <div class="wave-blue" />
      <div class="container">
        <div class="header">
          <img src="@/assets/images/credit-passport/CP-logo-transparent.svg" alt="cp-logo" width="140" height="50" />
        </div>
        <div class="card first-card">
          <div class="content">
            <h3 class="card-title">Is your business credit score as good as you think it is?</h3>
            <p>We’ll use your bank data to show how the financial system views your business in real time.</p>
            <p>It’s totally secure and <strong>doesn’t impact your personal or business credit scores.</strong></p>
            <button class="ui button btn-primary" @click="visitHealthCheckPage()">Understand your credit health</button>
            <modal name="creditScoreIntegrationModal" :scrollable="true" width="1100px" height="auto">
              <ProductListLocked
                lockedTitle="Getting your health check"
                lockedSubtitle="You’ll need to select who you bank with, and you’ll be redirected to your bank provider to complete your set up"
                icon1="banking"
                icon2="data"
                icon3="performance"
                lockedRouteName="credit-score"
                :creditScore="true"
                :companyId="companyId"
              />
            </modal>
          </div>
          <div class="image">
            <img class="phone-img" src="@/assets/images/credit-passport/phone.svg" alt="phone" width="342" height="341" />
          </div>
        </div>
        <div class="card blue">
          <div class="image">
            <img src="@/assets/images/credit-passport/laptop-mockup.svg" alt="Laptop" width="439" height="240" />
          </div>
          <div class="content">
            <h3 class="card-title white-text">Your free credit health check</h3>
            <div v-for="text in description" :key="text.title">
              <p class="card-subtitle white-text">{{ text.title }}</p>
              <p class="card-label white-text">{{ text.label }}</p>
            </div>
          </div>
        </div>
        <div class="card white">
          <div class="content">
            <h3 class="card-title">Understand and improve your credit</h3>
            <div v-for="text in copies" :key="text.title">
              <p class="card-label">{{ text.label }}</p>
            </div>
          </div>
          <div class="image">
            <img class="chart-img" src="@/assets/images/credit-passport/chart.svg" alt="chart" width="439" height="240" />
          </div>
        </div>
        <div class="card sand">
          <div class="icon-container" v-for="icon in icons" :key="icon.src">
            <img class="icon" :src="require(`@/assets/images/credit-passport/${icon.src}.svg`)" :alt="`${icon.src}`" />
            <p class="card-label">{{ icon.label }}</p>
          </div>
        </div>
        <div class="text-card">
          <img class="icon" src="@/assets/images/credit-passport/banking.svg" alt="banking" />
          <div>
            <p class="label-title">Open banking</p>
            <p class="label-copy">
              We use Open Banking data from your bank to calculate your Credit Passport. This is the new secure and regulated way for companies to provide read-only access to their
              banking data.
            </p>
          </div>
        </div>
        <div class="footer">
          <div class="align">
            <span class="white-text">Powered by</span>
            <img src="../../assets/images/credit-passport/cp-logo-white.svg" width="150" height="auto" alt="credit passport logo" />
          </div>
        </div>
        <FooterOutgoingLinks />
      </div>
    </div>
  </div>
</template>

<script>
import FooterOutgoingLinks from '@/components/FooterOutgoingLinks'
import { amplitudeEvents } from '@/utils/constants'
import ProductListLocked from '@product/components/products/ProductListLocked'
import Spinner from '@/components/Spinner'
import { bankGetters, bankMandateListRoutine } from '@/store/modules/bank/routines'
import { companyGetters } from '@/store/modules/company/routines'
import { mapGetters, mapActions } from 'vuex'
import deepLinking from '@/mixins/deepLinking'

export default {
  name: 'creditPassport',
  mixins: [deepLinking],
  data() {
    return {
      description: [
        { label: 'When trying to understand how risky it is to lend to your business, lenders look at your business’ bank account data and account filings. ' },
        { label: 'Link your bank account with Swoop, and we’ll help you understand what lenders see when they look at your business.' },
        { label: 'Your credit health check contains a business credit score and tips for improving your credit profile.' }
      ],
      icons: [
        { src: 'score', label: "This doesn't affect your credit score" },
        { src: 'data', label: 'You are in complete control of your data' },
        { src: 'fca', label: 'Authorised and regulated by the FCA' }
      ],
      copies: [
        { label: 'A good credit score makes it cheaper and easier to borrow. Knowing how lenders see your business empowers you to make the best decisions for your business.' },
        { label: 'Use our responsible credit checklist to make sure your credit footprint leaves the right impression.' },
        { label: 'You’ll see how your score changes over time, so you can make sure you’re heading in the right direction.' }
      ],
      loading: false
    }
  },
  components: { FooterOutgoingLinks, ProductListLocked, Spinner },
  async mounted() {
    this.$ma.trackEvent({
      eventType: amplitudeEvents.CreditScore.PAGE_VIEW
    })
    if (localStorage.getItem('truelayerIntegrationPrevRoute')) {
      this.$modal.show('creditScoreIntegrationModal')
    } else {
      // Check for bankmandate list and if available, redirect to credit score page
      await this.requestBankMandate()
    }
    this.checkForDeepLinkShift()
  },
  computed: {
    ...mapGetters({
      bankMandateStatus: bankGetters.BANK_MANDATE_STATUS,
      companyId: companyGetters.COMPANY_ID
    })
  },
  methods: {
    ...mapActions({
      getBankMandateList: bankMandateListRoutine.TRIGGER
    }),
    async requestBankMandate() {
      this.loading = true
      try {
        await this.getBankMandateList(this.companyId) // FetchingData, FetchingDataCompleted, ProcessingCompleted, Failed
      } catch (err) {
        // Do nothing
        console.error(err)
        return
      } finally {
        this.loading = false
      }
    },
    visitHealthCheckPage() {
      this.$ma.trackEvent({
        eventType: amplitudeEvents.CreditScore.CLICK_CTA
      })
      if (this.bankMandateStatus) {
        localStorage.removeItem('isHowItWorksModal')
        this.$router.push({ name: 'credit-score' })
      } else {
        localStorage.setItem('isHowItWorksModal', true)
        this.$modal.show('creditScoreIntegrationModal')
      }
    }
  },
  beforeMount() {
    this.visitHealthCheckPage()
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables';
@import '@/assets/styles/swoop/buttons';

/deep/.v--modal {
  padding: 0;
}

.background {
  background: $mixed-brand-gradient;

  &:after {
    content: '';
    overflow: hidden;
    right: 0;
    top: 0;
    position: absolute;
    width: 800px;
    height: 1000px;
    border-radius: 40%;
    background: linear-gradient(60deg, #6cc0a4 0%, #1e507a 100%);
    margin-right: 0px;
    margin-top: 60px;
    transform: scaleX(-1) scaleY(-1);
    opacity: 0.04;
  }

  &:before {
    content: '';
    overflow: hidden;
    right: 0;
    position: absolute;
    width: 900px;
    height: 1000px;
    border-radius: 60%;
    background: linear-gradient(90deg, #6cc0a4 0%, #1e507a 100%);
    transform: scaleX(-1) scaleY(-1);
    margin-right: 80px;
    opacity: 0.1;
    // circle overlapping sidebar fix
    @media only screen and (max-width: 1250px) {
      width: 65%;
    }
    @media only screen and (max-width: $breakpoint-sm-max) {
      width: 900px;
    }
  }
  .container {
    max-width: 1100px;
    position: relative;
    z-index: 1;
    padding: 0px 30px;

    @media only screen and (max-width: $breakpoint-sm-max) {
      padding: 0px 20px;
    }
    @media only screen and (max-width: 500px) {
      padding: 0px 10px;
    }

    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0;

      /deep/ .contact-wrapper {
        img {
          display: none;
        }
        span {
          color: $color-white;
        }
        span.material-icons {
          color: var(--color-tertiary-600);
          background-color: $color-white;
        }
      }
    }

    .card {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 0px;
      border-radius: 0;

      @media only screen and (max-width: $breakpoint-md-max) {
        flex-direction: column;
        padding: 50px 0;
        text-align: center;
        .chart-img {
          margin-right: 30px;
        }
      }

      @media only screen and (max-width: $breakpoint-sm-max) {
        .phone-img {
          margin-top: 30px;
        }
        .chart-img {
          margin-left: 10%;
        }
      }

      img {
        width: 100%;
        height: 100%;
        @media only screen and (max-width: $breakpoint-md-max) {
          width: 70%;
          height: 70%;
        }
      }

      .content {
        width: 45%;
        @media only screen and (max-width: $breakpoint-md-max) {
          width: 80%;
          text-align: left;
        }
        @media only screen and (max-width: $breakpoint-sm-max) {
          padding: 0px 0px;
        }
        .card-title {
          padding: 10px 0;
          font-size: 26px;
          font-weight: bold;
          line-height: 33px;
          @media only screen and (max-width: $breakpoint-md-max) {
            font-size: 22px;
          }
        }
        .card-subtitle {
          font-size: 18px;
          font-weight: bold;
          line-height: 22px;
          display: inline;
        }
        .card-label {
          font-size: 16px;
          line-height: 24px;
          @media only screen and (max-width: $breakpoint-md-max) {
            font-size: $font-size-base;
          }
        }
      }

      .icon-container {
        width: 150px;
        height: 100%;
        text-align: center;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        @media only screen and (max-width: $breakpoint-md-max) {
          width: 45%;
        }
        .icon {
          height: 50px;
          @media only screen and (max-width: $breakpoint-md-max) {
            margin: 0;
            width: 50px;
          }
        }
      }
    }

    .first-card {
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      justify-content: space-between;
      padding: 50px 70px;
      @media only screen and (max-width: $breakpoint-sm-max) {
        padding: 50px 0px;
      }
    }
    .blue {
      background: var(--color-tertiary-700);
      justify-content: space-between;
      padding: 50px 70px;
      @media only screen and (max-width: $breakpoint-sm-max) {
        padding: 50px 5px;
      }
    }
    .white {
      background: $color-white;
      justify-content: space-between;
      padding: 50px 0 50px 70px;
      .image {
        display: flex;
      }
      @media only screen and (max-width: $breakpoint-sm-max) {
        padding: 50px 5px;
      }
    }
    .white-text {
      color: $color-white;
    }
    .sand {
      background: var(--color-neutral-50);
      justify-content: space-between;
      padding: 50px 70px;
      @media only screen and (max-width: $breakpoint-sm-max) {
        padding: 50px 5px;
      }
    }

    .text-card {
      background: $color-white;
      width: 100%;
      height: 100%;
      padding: 4% 10%;
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
      display: flex;
      margin-bottom: 50px;
      @media only screen and (max-width: $breakpoint-md-max) {
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 0px;
        padding: 25px;
      }
      @media only screen and (max-width: $breakpoint-sm-max) {
        padding: 50px 5px;
      }
      img {
        margin-right: 30px;
      }
      .label-title {
        font-size: 17px;
        color: var(--color-primary-500);
        font-weight: 600;
        letter-spacing: 0.4px;
        display: inline;
      }

      .label-copy {
        font-size: 13px;
        color: var(--color-primary-400);
        letter-spacing: 0.1px;
        line-height: 18px;
        margin-top: 10px;
      }
    }
    .footer {
      display: flex;
      justify-content: center;
      .align {
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
    }
  }

  /deep/.outgoing-footer-links-widget {
    a.link {
      color: $color-white;
      font-size: 16px;
    }
    .link {
      color: $color-white;
      font-size: 16px;
    }
  }
}
</style>
